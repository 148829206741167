import _taggedTemplateLiteral from "/builds/schumacherweb/devbridge/scp-web-client/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: 10px;\n  font-weight: ", ";\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  display: inline-block;\n  font-size: 12px;\n  font-weight: ", ";\n  line-height: 1.1667;\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: 12px;\n  font-weight: ", ";\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n    color: ", ";\n    font-size: ", "px;\n    font-weight: ", ";\n    margin: 0;\n    text-decoration: none;\n\n    &:hover,\n    &:focus {\n      color: ", ";\n      text-decoration: none;\n    }\n\n    &:focus {\n      outline: none;\n    }\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    color: ", ";\n    font-size: 14px;\n    font-weight: ", ";\n    margin: 0;\n    opacity: 1;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n        font-style: italic;\n      "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    color: ", ";\n    font-size: 14px;\n    font-weight: ", ";\n    margin: 0 0 ", ";\n    ", "\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    color: ", ";\n    font-size: 14px;\n    font-weight: ", ";\n    line-height: ", ";\n    margin: 0 0 ", ";\n    text-transform: uppercase;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    color: ", ";\n    font-size: 16px;\n    font-weight: ", ";\n    line-height: ", ";\n    margin: 0 0 ", ";\n    text-transform: none;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    color: ", ";\n    font-size: 18px;\n    font-weight: ", ";\n    line-height: ", ";\n    margin: 0 0 ", ";\n    text-transform: none;\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled, { css } from 'styled-components';
import { color, typography } from "./variables";
var STYLES = {
  h1: css(_templateObject(), color.textPrimary, typography.fontWeightBold, typography.defaultLineHeight, function (props) {
    return props.noMargin ? 0 : '1em';
  }),
  h2: css(_templateObject2(), color.textPrimary, typography.fontWeightNormal, typography.defaultLineHeight, function (props) {
    return props.noMargin ? 0 : '1em';
  }),
  h3: css(_templateObject3(), color.text, typography.fontWeightSemiBold, typography.defaultLineHeight, function (props) {
    return props.noMargin ? 0 : '0.65em';
  }),
  p: css(_templateObject4(), function (props) {
    return props.secondary ? color.textSecondary : color.textPrimary;
  }, function (props) {
    return props.bold ? typography.fontWeightBold : typography.fontWeightNormal;
  }, function (props) {
    return props.noMargin ? 0 : '0.65em';
  }, function (props) {
    return props.italic && css(_templateObject5());
  }),
  placeholder: css(_templateObject6(), color.textSecondary, typography.fontWeightNormal),
  a: css(_templateObject7(), color.action, function (props) {
    return props.large ? 16 : 14;
  }, function (props) {
    return props.bold ? typography.fontWeightBold : typography.fontWeightNormal;
  }, color.actionHover)
};
export var Heading1 = styled.h1(_templateObject8(), STYLES.h1);
Heading1.css = STYLES.h1;
export var Heading2 = styled.h2(_templateObject9(), STYLES.h2);
Heading2.css = STYLES.h2;
export var Heading3 = styled.h3(_templateObject10(), STYLES.h3);
Heading3.css = STYLES.h3;
export var Paragraph = styled.p(_templateObject11(), STYLES.p);
Paragraph.css = STYLES.p;
export var Placeholder = styled.span(_templateObject12(), STYLES.placeholder);
Placeholder.css = STYLES.placeholder;
export var Link = styled.a(_templateObject13(), STYLES.a);
Link.css = STYLES.a;
export var Error = styled.span(_templateObject14(), color.invalid, typography.fontWeightNormal);
export var Subtext = styled.span(_templateObject15(), function (props) {
  return props.secondary ? color.textSecondary : color.textPrimary;
}, function (props) {
  return props.medium ? typography.fontWeightSemiBold : typography.fontWeightNormal;
});
export var Indicator = styled.span(_templateObject16(), color.indicator, typography.fontWeightNormal);