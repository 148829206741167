import _taggedTemplateLiteral from "/builds/schumacherweb/devbridge/scp-web-client/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  /* copy paste from UI-SHARED */\n  html {\n    font-size: ", "%;\n  }\n\n  /* font faces are already loaded on public HTML */\n  html,\n  body {\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    width: 100%;\n  }\n\n  body {\n    color: ", ";\n    font-family: ", ";\n    font-size: ", ";\n    font-weight: ", ";\n    line-height: ", ";\n    min-width: ", "px;\n  }\n\n  h1 {\n    ", "\n  }\n\n  h2 {\n    ", "\n  }\n\n  h3 {\n    ", "\n  }\n\n  h4 {\n    ", "\n  }\n\n  a {\n    ", "\n  }\n\n  p {\n    ", "\n  }\n\n  strong {\n    font-weight: ", ";\n  }\n\n  input,\n  select,\n  textarea {\n    color: ", ";\n    font-family: ", ";\n    font-size: ", ";\n    font-weight: ", ";\n  }\n\n  @media print {\n    * {\n      box-shadow: none !important;\n      text-shadow: none !important;\n    }\n\n    @page {\n      margin: 0.5cm;\n    }\n\n    body {\n      min-width: 320px;\n    }\n\n    html,\n    body {\n      overflow: hidden;\n    }\n\n    a,\n    a:visited {\n      text-decoration: underline;\n    }\n\n    pre,\n    blockquote {\n      border: 1px solid #000;\n      page-break-inside: avoid;\n    }\n\n    thead {\n      display: table-header-group;\n    }\n\n    tr,\n    img {\n      page-break-inside: avoid;\n    }\n\n    img {\n      max-width: 100% !important;\n    }\n\n    p,\n    h2,\n    h3 {\n      orphans: 3;\n      widows: 3;\n    }\n\n    h2,\n    h3 {\n      page-break-after: avoid;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { createGlobalStyle } from 'styled-components';
import { typography as sharedTypography } from '@scp/ui-shared/theme/variables';
import { color, size, typography } from "./variables";
import { Heading1, Heading2, Heading3, Link, Paragraph } from "./typography";
var DEFAULT_REM_SIZE = 16;
export default createGlobalStyle(_templateObject(), sharedTypography.remSizeInPx / DEFAULT_REM_SIZE * 100, color.textPrimary, typography.fontFamily, typography.defaultFontSize, typography.defaultFontWeight, typography.defaultLineHeight, size.minWidth, Heading1.css, Heading2.css, Heading3.css, Heading3.css, Link.css, Paragraph.css, typography.fontWeightBold, color.text, typography.fontFamily, typography.defaultFontSize, typography.defaultFontWeight);