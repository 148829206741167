import { rgba } from 'polished';
var PALETTE = {
  blue100: '#F2FAFF',
  blue200: '#40739E',
  blue450: '#0097E6',
  blue500: '#00A8FF',
  grey180: '#2F3640',
  grey200: '#353B48',
  grey400: '#687687',
  grey800: '#DCDDE1',
  grey900: '#F5F6FA',
  grey950: '#F9F9FB',
  red450: '#C23616',
  red500: '#E84118',
  green200: '#BFD22B',
  green500: '#4CD137',
  yellow300: '#F4A523',
  yellow500: '#FBC531',
  yellow700: '#E1B12C',
  purple200: '#BBAEFE'
};
export default {
  // Colors (semantic, depends on raw brand colors)
  primary: PALETTE.blue500,
  secondary: PALETTE.grey200,
  action: PALETTE.blue500,
  actionHover: PALETTE.blue450,
  heading: PALETTE.grey180,
  heading2: PALETTE.grey200,
  text: PALETTE.grey400,
  textPrimary: PALETTE.grey200,
  textBold: PALETTE.grey180,
  textSecondary: PALETTE.grey400,
  link: PALETTE.blue500,
  invalid: PALETTE.red450,
  disabled: PALETTE.grey400
  /* TODO: different color, this is same as normal text */
  ,
  backgroundDisabled: PALETTE.grey800,
  border: PALETTE.grey800,
  borderInput: PALETTE.grey400,
  background: '#fff',
  backgroundSecondary: PALETTE.grey900,
  success: PALETTE.green500,
  tableHeader: PALETTE.grey900
  /* only used for tables inside report page */
  ,
  avatarBackground: PALETTE.grey400,
  notification: PALETTE.red500,
  icon: PALETTE.grey400,
  iconHover: PALETTE.grey180,
  scpGreen: PALETTE.green200,
  label: PALETTE.grey400,
  rippleDefault: rgba('#000', 0.3),
  active2: PALETTE.yellow500,
  selected: PALETTE.grey400,
  indicator: PALETTE.red500,
  highlight: PALETTE.yellow700,
  active: PALETTE.blue100,
  warning: PALETTE.yellow500,
  // Color palette should only be used when there's no appropriate "semantic" color
  palette: PALETTE
};