import React from 'react';
import { render } from 'react-dom';
import { Root } from "./Root";
var containerEl = document.getElementById('root');
render(React.createElement(Root, null), containerEl);

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('./Root', function () {
      render(React.createElement(Root, null), containerEl);
    });
  }
}