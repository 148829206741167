import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LandingPage } from "../features/landing-page";
import GlobalStyles from "../theme/GlobalStyles";
export function App() {
  return React.createElement(React.Fragment, null, React.createElement(GlobalStyles, null), React.createElement(Switch, null, React.createElement(Route, {
    exact: true,
    path: "/",
    component: LandingPage
  }), React.createElement(Redirect, {
    to: "/"
  })));
}