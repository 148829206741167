/**
 * This file should contain everything related to fonts and typography.
 */
var baseFontWeight = 400;
export default {
  fontFamily: '"Roboto", sans-serif',
  fontWeightLight: 300,
  fontWeightNormal: baseFontWeight,
  fontWeightSemiBold: 500,
  fontWeightBold: 700,
  defaultLineHeight: 1.5,
  defaultFontSize: '14px',
  defaultFontWeight: baseFontWeight
};