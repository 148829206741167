import _taggedTemplateLiteral from "/builds/schumacherweb/devbridge/scp-web-client/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  backdrop-filter: blur(", ");\n  background-color: ", ";\n  box-shadow: ", " 0 ", " ", ";\n  height: ", ";\n  margin-bottom: 16vh;\n  padding: 0 ", ";\n  width: ", ";\n  @media only screen and (max-width: 780px) {\n    margin: ", " auto;\n  }\n  @media only screen and (max-height: 720px) {\n    margin-bottom: 0;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  left: ", ";\n  position: absolute;\n  top: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  align-items: center;\n  background: linear-gradient(\n    116deg,\n    ", " 0%,\n    ", " 50%\n  );\n  background-size: cover;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  overflow: auto;\n  position: relative;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  background-image: url(", ");\n  background-position: center;\n  background-size: cover;\n  height: 100%;\n  left: 0;\n  min-height: ", ";\n  overflow: auto;\n  position: fixed;\n  top: 0;\n  width: 100%;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { baselineToRem } from '@scp/ui-shared/theme/utils';
import { color } from '@scp/ui-shared/theme/variables';
import { Logo } from '@scp/ui-shared/components/brand';
import { Paper } from '@scp/ui-shared/components';
import { landingBackground } from "../../theme/assets/images";
var Screen = styled.div(_templateObject(), color.background, landingBackground, baselineToRem(150));
var Layer = styled.div(_templateObject2(), color.palette.white, rgba(color.palette.white, 0));
var SCPLogo = styled(Logo).attrs({
  name: 'scp-health-logo-text',
  width: baselineToRem(28),
  height: baselineToRem(18)
})(_templateObject3(), baselineToRem(12), baselineToRem(12));
var FloatingPaper = styled(Paper).attrs({
  noBorder: true
})(_templateObject4(), baselineToRem(12), rgba(color.palette.white, 0.9), rgba(color.palette.grey700, 0.2), baselineToRem(2), baselineToRem(6), baselineToRem(125), baselineToRem(4), baselineToRem(110), baselineToRem(30));
export var LandingPageLayout = function LandingPageLayout(_ref) {
  var children = _ref.children;
  return React.createElement(Screen, null, React.createElement(Layer, null, React.createElement(SCPLogo, {
    name: "scp-health",
    size: baselineToRem(28)
  }), React.createElement(FloatingPaper, null, children)));
};
LandingPageLayout.defaultProps = {
  children: null
};