import _taggedTemplateLiteral from "/builds/schumacherweb/devbridge/scp-web-client/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  line-height: ", ";\n  margin: 0 auto ", ";\n  width: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin: ", " auto ", ";\n  text-align: center;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import React from 'react';
import styled from 'styled-components';
import { AppLinkCard } from '@scp/ui-shared/components/cards/';
import { baselineToRem } from '@scp/ui-shared/theme/utils';
import { Paragraph } from '@scp/ui-shared/components/typography';
import { LandingPageLayout } from "./LandingPageLayout";
var TEXT = {
  TITLE: 'Welcome to the mySCP Product Family'
};
var PromoTitle = styled(Paragraph).attrs({
  bold: true,
  as: 'h1'
})(_templateObject(), baselineToRem(11), baselineToRem(8));
var AppHubCard = styled.div(_templateObject2(), baselineToRem(5), baselineToRem(2), baselineToRem(102));
var myScpApps = [{
  id: 'connect',
  url: 'https://connect.myscp.com',
  iconName: 'scp-connect-logo',
  title: 'mySCP Connect',
  subTitle: 'Secure communications with the SCP Team and providers, via direct or group messaging'
}, {
  id: 'practice',
  url: 'https://practice.myscp.com',
  iconName: 'scp-practice-logo',
  title: 'mySCP Practice',
  subTitle: 'Interactive individual and group scheduling, onboarding progress tracking, and document management'
}, {
  id: 'myscp',
  url: 'https://www.myscphealth.com',
  iconName: 'scp-health-logo',
  title: 'mySCP Health Portal',
  subTitle: 'SCP Health news and resources'
}, {
  id: 'care',
  url: 'https://care.myscp.com',
  iconName: 'scp-care-logo',
  title: 'mySCP Care',
  subTitle: 'Value-based patient management, encounters and MIPS'
}];
export var LandingPage = function LandingPage() {
  return React.createElement(LandingPageLayout, null, React.createElement(PromoTitle, null, TEXT.TITLE), myScpApps.map(function (app) {
    return React.createElement(AppHubCard, {
      key: app.id
    }, React.createElement(AppLinkCard, Object.assign({}, app, {
      titleElement: "h2"
    })));
  }));
};