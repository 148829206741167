'use strict';

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();

  window.Promise = require('promise/lib/es6-extensions.js');
} // Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.


Object.assign = require('object-assign'); // new Set(iterable) is not supported in internet explorer 11
// checking the set size when an iterable is applied indicates if we need to polyfill it or not

if (!Set || new Set([0]).size === 0) {
  require('core-js/fn/set');
}

if (!Array.prototype.find || !Array.prototype.flatMap) {
  require('core-js/fn/array');
}

if (!Object.values) {
  require('core-js/fn/object');
}

if (!String.endsWith) {
  require('core-js/fn/string');
} // Support name property of function objects in Internet Explorer


if (!Object.constructor.prototype.hasOwnProperty('name')) {
  Object.defineProperty(Object.constructor.prototype, 'name', {
    get: function get() {
      return this.toString().trim().replace(/^\S+\s+(\w+)[\S\s]+$/, '$1');
    }
  });
}